export const callExternalApi = async (options) => {
  try {
    const response = await fetch(options.config.url, {
      method: options.config.method,
      headers: options.config.headers,
      body: options.config.body,
    });

    if (!response.ok) {
      throw new Error(response.statusText || 'HTTP request failed');
    }

    const data = await response.json();

    return {
      data,
      error: null,
    };
  } catch (error) {
    let message = 'HTTP request failed';

    if (error.message) {
      message = error.message;
    }

    return {
      data: null,
      error: {
        message,
      },
    };
  }
};
