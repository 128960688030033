import React, { Suspense, useState, useEffect } from 'react';
import { Placeholder } from 'react-bootstrap';

const Chart = React.lazy(() => import('react-apexcharts'));

const WeekChart = (props) => {
  const [series, setSeries] = useState([{ name: props.symbol, data: [] }]);
  const [options, setOptions] = useState({
    noData: {
      text: " ",
      verticalAlign: "middle",
      align: 'center',
    },
    chart: {
      animations: {
        enabled: false,
      },
      type: 'line',
      width: 48,
      height: 164,
      sparkline: {
        enabled: true
      },
      background: '#f8f9fa'
    },
  });

  const updateCharts = () => {
    var chart = props.rawchart.map(x => ({
      'x': x.Time,
      'y': x.Last
    }));
    setSeries([{ data: chart, name: props.symbol }]);
    setOptions({
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      chart: {
        animations: {
          enabled: false,
        },
        type: 'line',
        width: 48,
        height: 164,
        sparkline: {
          enabled: true
        },
        background: 'transparent'
      },
      colors: [
        (chart[0].y <= chart[chart.length - 1].y) ? '#1eb980' : '#C70039'
      ],
      stroke: {
        width: 2,
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
        marker: {
          show: false
        },
        y: {
          title: {
            formatter: (seriesName) => seriesName.slice(0, -3),
          },
          formatter: function (value) {
            return (value > 0.1) ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + value.toLocaleString('pt-BR', { style: 'decimal', maximumSignificantDigits: 8 }))
          }
        },
      }
    })
  }

  useEffect(() => {
    let timer1 = setTimeout(() => updateCharts(), 10);
    return () => {clearTimeout(timer1);}; 
  })

  if (series[0] === undefined) {
    <span className="text-muted">Erro</span>
  } else {
    return (
      <Suspense fallback={<Placeholder.Button animation="glow" variant="light" style={{ minHeight: "48px", maxHeight: "48px", minWidth: "164px", maxWidth: "164px" }} aria-hidden="true" />}>
        <div className="rounded"><Chart
          options={options}
          series={series}
          type="line"
          height="48"
          width="164"
        /></div>
      </Suspense>
    );
  }
}
export default WeekChart;