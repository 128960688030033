import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col } from 'react-bootstrap';
import ExchangesTable from './components/ExchangesTable'
import Treemap from "./charts/ExchangeTreeMap";
import { ReactComponent as Caret } from './components/caret-up-fill.svg';
import { doFetchJson } from "./utils/api";
import { Helmet } from "react-helmet";
import MainAd from "./components/MainAd.js";
import AdReports from "./utils/AdReports";

const Exchanges = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false)
  const [exchanges, setExchanges] = useState([])

  useEffect(() => {
    function getSummary() {
      fetch(process.env.REACT_APP_API_URL + "/v1/exchanges/all")
        .then(res => doFetchJson(res))
        .then(res => {
          setExchanges(res);
          setIsLoaded(true);
        })
        .catch((error) => {
          setError(error);
          setIsLoaded(true);
        });
      Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
    }
    let timer = setInterval(() => (getSummary()), 15000);
    getSummary();
    return () => { clearInterval(timer); }
  }, []);

  let volumevar = ((exchanges.reduce((a, e) => a + e.QuoteVolume, 0)) - (exchanges.reduce((a, e) => a + e.YesterdayVolume, 0))) / (exchanges.reduce((a, e) => a + e.YesterdayVolume, 0));
  if (error) {
    return <Container className="py-3"><h5 className="mt-5 pt-5 text-center text-muted">{error.message}</h5></Container>;
  } else if (!isLoaded) {
    return <Container className="text-center p-5"><br /><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {

    const BreadcrumbList = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Criptomoedas",
          "item": "https://mercadocripto.livecoins.com.br/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Corretoras",
          "item": "https://mercadocripto.livecoins.com.br/corretoras",
        }
      ]
    };

    const FAQPage = {
      "@context": "http://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Qual a maior corretora de criptomoeda do Brasil em volume?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "A maior corretora de criptomoedas que opera no Brasil é a " + exchanges[0].Exchange + ". Nessa corretora foram negociados um total de " + exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + " nas últimas 24 horas, somando apenas todos os pares com BRL."
          }
        },
        {
          "@type": "Question",
          "name": "Quantas corretoras de criptomoedas aceitam PIX ou TED de real brasileiro (BRL)?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Existem hoje um total de " + exchanges.lenght + " corretoras de criptomoedas operando no Brasil, e todas essas aceitam PIX ou TED para depósitos e retiradas."
          }
        },
        {
          "@type": "Question",
          "name": "Qual o volume diário de criptomoedas negociadas no Brasil?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "O volume total de criptomoedas negociados nas últimas 24 horas apenas no Brasil é de " + exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ", somando todos os pares com BRL."
          }
        },
      ]
    }

    return (
      <>
        <Helmet>
          <title>Ranking de corretoras brasileiras de criptomoedas por volume · MercadoCripto</title>
          <meta name="description" content="Ranking de corretoras brasileiras de criptomoedas, com detalhes do volume diário, o preço de cada par negociado. Acesso a gráficos, diferenças de preço (ágio/spread), e dados históricos de todas as corretoras do Brasil." />
          <script type="application/ld+json">{JSON.stringify(BreadcrumbList)}</script>
          <script type="application/ld+json">{JSON.stringify(FAQPage)}</script>
        </Helmet>
        <div className="my-4">
          <Container >
            <div className="d-none d-lg-block pb-1" >
              <AdReports/>
            </div>

            <Row>
              <Col>
                <h1 className="h1 display-5 fw-medium ">Corretoras nacionais</h1>
                <p className="lead my-4 fw-normal">Corretoras que operam com <span>Real Brasileiro (BRL)</span>.</p>
                <Row className="my-3">
                  <Col sm={5}>
                    <h2 className="h6 fw-semibold">Volume negociado (BRL&nbsp;24h)</h2>
                    <div className="d-none d-lg-block">
                      <span className="h5">{exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                      {volumevar >= 0
                        ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                        : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)} </span>}
                    </div>
                    <div className="d-lg-none ">
                      <span className="h6">{exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                      {volumevar >= 0
                        ? <span className="h6 text-success text-nowrap small"> <Caret className="icontable" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                        : <span className="h6 text-danger text-nowrap small"> <Caret className="icon-rotated" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)} </span>}
                    </div>
                    <div className="my-4 d-grid gap-2">
                      <MainAd header="true" />
                    </div>
                  </Col>
                  <Col className="text-center d-none d-md-block">
                    <h3 className="h6 fw-semibold">Corretoras</h3>
                    <span className="h5">{exchanges.length}</span>
                  </Col>
                </Row>

              </Col>
              <Col md={7}>
                <div className="p-2 px-3 mt-3">
                  <Treemap data={exchanges} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="overflow-auto p-2 my-5">
          <ExchangesTable data={exchanges} id="table" />
        </Container>

      </>
    );
  }
}

export default Exchanges;