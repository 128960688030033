import React, { useEffect } from "react";
import { Container, Alert } from 'react-bootstrap';

const Contato = () => {
    useEffect(() => {
        document.title = "Entre em contato · MercadoCripto"
    }, []);
    return (
        <Container className="p-3 mt-3 about">
            <h1 className="pb-4 h5 font-weight-bolder">Entre em contato com o mercadocripto</h1>
            <div className="px-3 pt-1 pb-3">
                <p>Para qualquer contato sobre informações, sugestões de funcionalidades, dicas, informar um erro ou informações sobre anúncio, envie um e-mail para <Alert.Link href='mailto:mercadocripto@livecoins.com.br'>mercadocripto@livecoins.com.br</Alert.Link>
                </p>
            </div>
            <Alert variant="info" className="border-info">
                <Alert.Heading>Você tem uma corretora?</Alert.Heading>
                <p>Entre em contato para anunciar ou para listar sua corretora: <Alert.Link href='mailto:mercadocripto@livecoins.com.br'>mercadocripto@livecoins.com.br</Alert.Link></p>
            </Alert>
        </Container>
    )
}

export default Contato