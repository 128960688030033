import React, { useState, useEffect } from "react";
import { doFetchJson } from "../utils/api";
import { Card, ListGroup, Placeholder, Col, Row } from 'react-bootstrap';

const SymbolHistoricalData = (props) => {
    var symbol = props.symbol
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false)
    const [hist, setHist] = useState([])
    const [month, setMonth] = useState([])

    useEffect(() => {
        const getHistory = () => {
            fetch(process.env.REACT_APP_API_URL + "/v1/ticker/historical/" + symbol.toUpperCase())
                .then(res => doFetchJson(res))
                .then(res => {
                    setHist(res)
                    setIsLoaded(true)
                })
                .catch((error) => {
                    setError(error)
                    setIsLoaded(true)
                })
        };
        const getHistoryMonth = () => {
            fetch(process.env.REACT_APP_API_URL + "/v1/ticker/historicalmonth/" + symbol.toUpperCase())
                .then(res => doFetchJson(res))
                .then(res => {
                    setMonth(res)
                })
                .catch((error) => {
                    console.warn("Histórico Mensal "+error)
                })
        };
        getHistory();
        getHistoryMonth();
    }, [symbol]);


    const hoje = new Date(Date.now());
    const hojeoptions = { year: 'numeric', month: 'long', day: 'numeric' };

    if (error) {
        return <>{console.info(error.message)}</>
    } else if (!isLoaded) {
        return (
            <>
                <h4>Dados Históricos do {symbol}</h4>
                <p className="h6 py-2">{hoje.toLocaleDateString('pt-BR', hojeoptions)}</p>

                <Row xs={1} md={3} className="g-3 mb-3">
                    <Col>
                        <Card bg="light">
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={5} className="mx-3" /> <Placeholder className="ms-3" xs={4} />{' '}
                                    <Placeholder xs={5} className="mx-3" /> <Placeholder className="ms-3" xs={4} />{' '}
                                    <Placeholder xs={5} className="mx-3" /> <Placeholder className="ms-3" xs={4} />{' '}
                                    <Placeholder xs={5} className="mx-3" /> <Placeholder className="ms-3" xs={4} />{' '}
                                    <Placeholder xs={5} className="mx-3" /> <Placeholder className="ms-3" xs={4} />{' '}
                                    <Placeholder xs={5} className="mx-3" /> <Placeholder className="ms-3" xs={4} />{' '}
                                </Placeholder>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>)
    } else {
        return (
            <>
                <h4>Dados Históricos do {symbol}</h4>
                <p className="h6 py-2">{hoje.toLocaleDateString('pt-BR', hojeoptions)}</p>
                <Row xs={1} md={2} lg={3} className="g-5 mb-3">
                    <Col>
                        <Card bg="light">
                            <Card.Body>
                                <Card.Title>Anual</Card.Title>
                                {hist.map((i, idx) => (
                                    <ListGroup horizontal className='border-bottom my-2' key={i.Avg}>
                                        <ListGroup.Item className="border-0 me-auto" >{(new Date(i.Date)).toLocaleDateString('pt-BR')}</ListGroup.Item>
                                        <ListGroup.Item className="border-0 text-bold" >{i.Avg.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</ListGroup.Item>
                                    </ListGroup>))}
                            </Card.Body>
                        </Card>
                    </Col>
                    {(month.length > 0) ?
                    <Col>
                        <Card bg="light">
                            <Card.Body>
                                <Card.Title>Últimos 12 meses</Card.Title>
                                {month.map((i, idx) => (
                                    <ListGroup horizontal className='border-bottom my-2' key={i.Avg}>
                                        <ListGroup.Item className="border-0 me-auto">{(new Date(i.Date)).toLocaleDateString('pt-BR')}</ListGroup.Item>
                                        <ListGroup.Item className="border-0 text-bold">{i.Avg.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</ListGroup.Item>
                                    </ListGroup>))}
                            </Card.Body>
                        </Card>
                    </Col>
                    : ''}
                </Row>
            </>
        );
    }
}

export default SymbolHistoricalData;