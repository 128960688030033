import React from 'react';
import { Nav } from 'react-bootstrap';
import coinlogo from "../logos/coinlogo.svg";
import exchangelogo from "../logos/exchangelogo.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Rocket } from '../svg/rocket.svg';

const CoinList = ({ coinList = [], exchangeList = [], onClick: setKeyword }) => {
    return (
        <>
            <div tabIndex="-1" id="searchbar-dropdown" className="searchdropdown shadow-sm skipBlur">
                <Nav.Link className="skipBlur" as={Link} to={"/contato"}  onClick={(event) => { setKeyword(event.target.value = '') }}>
                    <div className="my-2 mx-1">
                    <p style={{lineHeight:"0.8"}}><Rocket className="searchad float-left"/>Sua marca aqui.</p>
                    <p className="small text-muted m-0 p-0"  style={{lineHeight:"0"}}>Patrocinado</p>
                    </div>
                </Nav.Link>

                <span className="pb-2 mx-2 text-dark fs-5"> Criptomoedas</span>

                {coinList.map((data, index) => {
                    if (data) {
                        return (
                            <Nav.Link className="skipBlur" as={Link} to={"/" + data.Symbol} key={data.Symbol} onClick={(event) => { setKeyword(event.target.value = '') }}>
                                <svg className="searchbarlogo" role="img" aria-labelledby="title">
                                    <title id="title">{data.SymbolName}</title>
                                    <use href={coinlogo + "#" + (data.Symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
                                </svg>
                                {data.SymbolName} <span className="text-muted">{data.Symbol.slice(0, -3)}</span>
                            </Nav.Link>
                        )
                    }
                    return null
                })}

                <span className="pb-2  mx-2 text-dark fs-5">Corretoras</span>
                {exchangeList.map((data, index) => {
                    if (data) {
                        return (
                            <Nav.Link className="skipBlur" as={Link} to={"/corretoras/" + data.Name} key={data.Name} onClick={(event) => { setKeyword(event.target.value = '') }}>
                                <svg className="searchbarlogo" role="img" aria-labelledby="title">
                                    <title id="title">{data.Name}</title>
                                    <use href={exchangelogo+  "#" + (data.Name.toLowerCase())}></use>
                                </svg>
                                {data.Name}
                            </Nav.Link>
                        )
                    }
                    return null
                })}
            </div>

        </>
    );
}

export default CoinList