import React from 'react';
import { FormControl } from 'react-bootstrap';

const SearchBar = ({ input: keyword, onChange: setKeyword }) => {

    const checkOnBlur = (event) => {
        if(event.relatedTarget && event.relatedTarget.classList.contains("skipBlur")){
            return
        }
        setKeyword("");
    }

    return (
        <FormControl
            className="searchbar searchicon"
            key="searchBar"
            value={keyword}
            type="search"
            placeholder="Busque ativos ou corretoras."
            onChange={(e) => setKeyword(e.target.value)}
            onBlur={(e) => checkOnBlur(e)}
        />
    );
}

export default SearchBar