import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const Terms = () => {
  useEffect(() => {
    document.title = "Termos de Uso · MercadoCripto";
  }, []);
  return (
    <Container className="p-3 mt-3 about">
      <h1 className="pb-4 h5 font-weight-bolder">Termos de Uso</h1>
      <div className="px-3 pt-1 pb-3">
        <p>
          Estes Termos de Uso regem o uso do serviço de dados de mercado
          fornecido pelo MercadoCripto. Ao prosseguir com o pagamento e utilizar
          nossos serviços, você concorda com os seguintes termos:
        </p>
        <p>
          Serviço de Dados de Mercado: Nossa empresa fornece acesso a dados de
          mercado mediante pagamento prévio. Os dados disponibilizados são de
          propriedade da nossa empresa e são destinados exclusivamente ao uso do
          cliente.
          <p>
            Pagamento e Acesso aos Dados: O cliente deve efetuar o pagamento
            conforme as condições estabelecidas para ter acesso aos dados de
            mercado disponibilizados pela nossa empresa.
          </p>
          <p>
            Uso dos Dados: Os dados de mercado fornecidos são para uso exclusivo
            do cliente e não podem ser compartilhados, vendidos ou utilizados
            para outros fins sem autorização prévia da nossa empresa.
          </p>
          <p>
            Responsabilidade do Cliente: O cliente é responsável por garantir a
            segurança e confidencialidade dos dados de mercado obtidos através
            do nosso serviço.
          </p>
          <p>
            Consentimento para Uso dos Dados: Ao utilizar nossos serviços, o
            cliente concorda em cumprir estes Termos de Uso e em não utilizar os
            dados de mercado de forma inadequada ou prejudicial.
          </p>
          <p>
            Ao prosseguir com o pagamento e utilizar nossos serviços de dados de
            mercado, você concorda com os termos estabelecidos acima. Em caso de
            dúvidas ou necessidade de esclarecimentos adicionais, entre em
            contato conosco.
          </p>
        </p>
      </div>
    </Container>
  );
};

export default Terms;
