import React, { useState, useEffect } from "react";
import { Container, Col, Card, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import coinlogo from "../components/logos/coinlogo.svg";
import { ReactComponent as Caret } from './caret-up-fill.svg';

function NotFound() {
    const [coins, setCoins] = useState([])

    const getCoins = React.useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "/v1/ticker/last?symbols=BTCBRL,ETHBRL,USDTBRL")
            .then(res => res.json())
            .then(res => {
                setCoins(res)
            })
            .catch((error) => {
                console.log(error)

            })
    }, []);

    useEffect(() => {
        let timer = setInterval(() => (getCoins()), 15000);
        getCoins();
        return () => { clearInterval(timer); }
    }, [getCoins]);

    return (
        <Container className="text-center mt-1" id="p404">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <span className="text-primary">404</span>
                </div>
                <div className="col-md-5 my-auto">
                    <p>A página que você está tentando acessar não existe.</p>
                    <p>Verifique se você escreveu o endereço corretamente.</p>
                    <p>Entre em <Link to="/contato">contato</Link> para que possamos <Link to="/contato">ajudar</Link>.</p>
                </div>
            </div>
                <Row className="justify-content-md-center">
                    {coins.map((i, idx) => (
                        <Col md="2" key={idx}>
                            <Card className="feed gy-4">
                                <Card.Body>
                                <Link to={"/" + i.Symbol} className="stretched-link"></Link>
                                <svg className="coinlogo mb-1 text-center" title={i.SymbolName} role="img" aria-labelledby="title">
                                    <title>{i.Symbol}</title>
                                    <use href={coinlogo + "#" + (i.Symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
                                </svg>
                                    <Card.Title>{i.Symbol}</Card.Title>
                                    <Card.Text>
                                            {i.Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br/>
                                        {((i.Last - i['24h']) / i.Last) >= 0 ?
                                            <span class='text-success'><strong>  {((i.Last - i['24h']) / i['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}<Caret className="icontable" /></strong></span>
                                            : <span class="text-danger"><strong> {((i.Last - i['24h']) / i['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}<Caret className="icon-rotated" /></strong></span>
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Link to="/" className="btn btn-primary py-3 px-4 my-3">Voltar ao início</Link>
        </Container>
    )
}



export default NotFound