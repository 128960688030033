import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import coinlogo from "./components/logos/coinlogo.svg";
import DcaChart from "./charts/DcaChart";
import { Helmet } from "react-helmet";

export default function Dca() {
    const [selectedCrypto, setSelectedCrypto] = useState('BTC');
    const [selectedInvest, setSelectedInvest] = useState(100.00);
    const [selectedFreq, setSelectedFreq] = useState('30');

    const formattedDate = new Date(new Date().getTime() - (31536000 * 2000)).toISOString().split('T')[0];
    const [selectedStart, setSelectedStart] = useState(formattedDate);

    const [totalInvestedChart, setTotalInvestedChart] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const data = useData(selectedCrypto);

    function isBuyDay(date, selectedFreq, selectedStart) {
        selectedFreq = parseInt(selectedFreq);
        selectedStart = new Date(selectedStart).getTime()
        if (selectedFreq === 365) {
            const month = new Date(date).getMonth();
            const day = new Date(date).getDate();
            return (
                day === new Date(parseInt(selectedStart)).getDay() &&
                month === new Date(parseInt(selectedStart)).getMonth()
            )
        }
        else if (selectedFreq === 30) {
            const day = new Date(date).getDate();
            return day === 1;
        } else if (selectedFreq === 15) {
            const day = new Date(date).getDate();
            return day === 1 || day === 15;
        } else if (selectedFreq === 7) {
            const day = new Date(date).getUTCDay();
            return day === 1;
        } else if (selectedFreq === 1) {
            const day = new Date(date).getUTCDay();
            return day < 7;
        }
    }

    function findClosestIndex(data, target) {
        var closest = Math.abs(new Date(data[0].Time) - target);
        var index = 0;
        for (var i = 1; i < data.length; i++) {
            var diff = Math.abs(new Date(data[i].Time) - target);
            if (diff < closest) {
                closest = diff;
                index = i;
            }
        }
        return index;
    }

    function useData(selectedCrypto) {
        const [data, setData] = useState(null);
        useEffect(() => {
            let ignore = false;
            const fetchData = async () => {
                try {
                    const response = await fetch(
                        (process.env.REACT_APP_API_URL) + '/v1/ticker/mainchart?frequency=86400&symbol=' + selectedCrypto + 'BRL&interval=9000000')
                    const json = await response.json();
                    if (!ignore) {
                        setData(json);
                    }
                } catch (error) {
                    setError(error);
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
            return () => {
                ignore = true;
            };
        }, [selectedCrypto]);
        return data;
    }

    useEffect(() => {
        document.title = "DCA Cripto · MercadoCripto";
        function sliceChart(chart, date) {
            const simulationStartedAt = findClosestIndex(chart, new Date(date).getTime())
            return chart.slice(simulationStartedAt - (chart.length - 1));
        }
        function renderChart(data) {
            let slicedData = sliceChart(data, selectedStart);
            let newTotalInvested = 0;
            let newBtcStash = 0;
            let newBrlStash = 0;
            let totalInvestArray = []
            slicedData.forEach((i) => {
                const timestamp = i.Time;
                if (isBuyDay(timestamp, selectedFreq, selectedStart)) {
                    newTotalInvested += parseFloat(parseFloat(selectedInvest).toFixed(2))
                    newBtcStash += parseFloat((selectedInvest / i.Last).toFixed(8));
                    newBrlStash = newBtcStash * i.Last;
                } else {
                    newBrlStash = newBtcStash * i.Last;
                }
                totalInvestArray.push({
                    'time': i.Time,
                    'totalInvested': newTotalInvested,
                    'btcStash': newBtcStash,
                    'brlStash': newBrlStash
                });
            });
            setTotalInvestedChart(totalInvestArray)
            setIsLoaded(true);
        }
        setIsLoaded(false);
        setTotalInvestedChart([]);
        if (data) {
            renderChart(data);
        }
    }, [data, selectedFreq, selectedInvest, selectedStart]);

    if (error) {
        return <><Container><div className="alert alert-warning mt-5">  <h4 class="alert-heading">
        Erro de carregamento.</h4>
        Carregue a página novamente ou tente novamente mais tarde.
            <p>{error.message}</p></div></Container></>
    } else {
        return (
            <>
                <Helmet>
                    <title>Ranking de corretoras brasileiras de criptomoedas por volume · MercadoCripto</title>
                    <meta name="description" content="Simulador de Dollar-Cost Averaging (DCA). Simule um investimento de adquirir Bitcoin (BTC) regularmente, em intervalos e valores customizáveis." />
                </Helmet>
                <Container fluid="lg" className="p-3 my-3 about">
                    <h1 className="h1 display-5 fw-medium">Dollar Cost Average
                        <svg className="exchangelogo d-inline ms-2 opacity-90" role="img" aria-labelledby="title">
                            <title>{selectedCrypto}</title>
                            <use href={coinlogo + "#" + (selectedCrypto).toLowerCase() + "-logo"}></use>
                        </svg>
                    </h1>
                    <Row className="my-2 g-4">
                        <Col>
                            <div className="bg-body-tertiary border-primary-subtle border-start border-4 p-4 fw-medium">
                                <h3 className=' h5'>Investido</h3>
                                <h3>{!isLoaded ? <Spinner role="status" animation="border fs-5 fw-lighter" size="sm" variant="primary" /> :
                                    (totalInvestedChart[totalInvestedChart.length - 1].totalInvested).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </h3>
                            </div>
                        </Col>
                        <Col>
                            <div className="bg-body-tertiary border-primary-subtle border-start border-4 p-4">
                                <h3 className='fw-medium h5'>Valor Atual</h3>
                                <h3>{!isLoaded ? <Spinner role="status" animation="border fs-5 fw-lighter" size="sm" variant="primary" /> :
                                    <>{(totalInvestedChart[totalInvestedChart.length - 1].brlStash).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        <span className="text-muted h4 ms-3 text-nowrap">{(totalInvestedChart[totalInvestedChart.length - 1].btcStash).toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 4 })} {selectedCrypto}</span>
                                    </>}
                                </h3>
                            </div>
                        </Col>
                        <Col>
                            <div className="bg-body-tertiary border-primary-subtle border-start border-4 p-4">
                                <h3 className='fw-medium h5'>Rentabilidade</h3>
                                <h3>{!isLoaded ? <Spinner role="status" animation="border fs-5 fw-lighter" size="sm" variant="primary" /> :
                                    (((totalInvestedChart[totalInvestedChart.length - 1].brlStash) - (totalInvestedChart[totalInvestedChart.length - 1].totalInvested))/(totalInvestedChart[totalInvestedChart.length - 1].totalInvested)).toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h3>
                            </div>
                        </Col>
                    </Row>

                    <div className="px-3 pt-1 pb-3">
                        <Row>
                            <Col md={3}>
                                <Form>

                                    <Form.Label htmlFor="criptomoeda">Criptomoeda</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select value={selectedCrypto} aria-label="criptomoeda" onChange={e => setSelectedCrypto(e.target.value)}>
                                            <option value="BTC" key="BTC">Bitcoin</option>
                                            <option value="ETH" key="ETH">Ethereum</option>
                                            <option value="SOL" key="SOL">Solana</option>
                                            <option value="USDT" key="USDT">USDT</option>
                                        </Form.Select>
                                    </InputGroup>

                                    <Form.Label htmlFor="Investimento">Valor da Compra</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>R$</InputGroup.Text>
                                        <Form.Control type='number' min={0} step={100} aria-label="Investimento" defaultValue={selectedInvest} onChange={e => setSelectedInvest(e.target.value)} />
                                    </InputGroup>

                                    <Form.Label htmlFor="Frequencia">Frequência de compra</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select value={selectedFreq} aria-label="Frequencia" onChange={e => setSelectedFreq(e.target.value)}>
                                            <option value="1" key='1'>Diária</option>
                                            <option value="7" key='7'>Semanal</option>
                                            <option value="15" key='15'>Quinzenal</option>
                                            <option value="30" key='30'>Mensal</option>
                                            <option value="365" key='365'>Anual</option>
                                        </Form.Select>
                                    </InputGroup>

                                    <Form.Group className="mb-3" controlId="inicio">
                                        <Form.Label>Início</Form.Label>
                                        <Form.Control type="date" value={selectedStart} onChange={e => setSelectedStart(e.target.value)} min="2010-08-02" />
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={9}>
                                <DcaChart symbol={selectedCrypto} data={totalInvestedChart} isLoaded={isLoaded} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </>
        )
    }
}