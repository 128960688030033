import React from "react";
import { Container, Alert } from 'react-bootstrap';

const Error = (props) => {
    const error = props.error
    return (
        <Container className="p-5 mt-2 text-center">
            <Alert variant="warning" className="bd-callout p-4 border-warning" >
                <p>{error.message}</p>
            </Alert>
        </Container>
    )
}

export default Error