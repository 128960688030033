import React from "react";
import { ListGroup } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import logo from './logos/mercadocripto-logo.png';

const Footer = () => (
    <footer className="bg-light border-0">
        <ListGroup id="footer" horizontal={'md'} className="py-3 justify-content-evenly text-center">
            <ListGroup.Item className="footerlink my-1 nav-link border-0"><img src={logo} alt="mercadocripto" title="mercadocripto" width="170" height="25.5" style={{ WebkitFilter: "invert(100%)", filter: "invert(100%)" }} /></ListGroup.Item>
            <ListGroup.Item as={NavLink} className="footerlink my-1 nav-link border-0" to="/contato">Contato</ListGroup.Item>
            <ListGroup.Item as={NavLink} className="footerlink my-1 nav-link border-0" to="/metodologia">Metodologia</ListGroup.Item>
            <ListGroup.Item as={NavLink} className="footerlink mt-1 nav-link border-0" to="/widget">Widget</ListGroup.Item>
        </ListGroup>
        <div className="mb-5 d-xs-block d-md-none"></div>
    </footer>
)
export default Footer