import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/profile",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <>
      <button
        className="mt-auto btn btn-light d-none d-lg-inline-block no-break"
        onClick={handleLogin}
      >
        Entrar
      </button>
      <button
        className="mt-auto btn btn-primary d-lg-none no-break"
        onClick={handleLogin}
      >
        Entrar
      </button>
    </>
  );
};
