import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useEffect, useState } from "react";
import { Col, Container, ListGroup, Row, Image } from "react-bootstrap";
import { getAdminResource } from "./services/message.service";

export const ProfilePage = () => {
  const [message, setMessage] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true;
    document.title = "Sua conta · MercadoCripto";

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAdminResource(accessToken,'profilestatus');

      if (!isMounted) {
        return;
      }

      if (!!data && data.message === "yes") {
        setMessage("Plano Pro");
      }
      else {
        setMessage("Gratuito")
      }

      if (error) {
        setMessage("Gratuito");
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  const { user } = useAuth0();

  if (!user) {
    return null;
  }

  const date = new Date(user.updated_at);
  date.setHours(date.getHours() - 3);
  const formattedDate = date.toLocaleString("pt-BR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });



  return (
    <>
      <title>Sua conta · MercadoCriptoBR</title>
      <Container className="py-5">
        <Row>
          <Col md={6}>
            <div className="d-flex align-items-center">
              <Image src={user.picture} alt="Profile Picture" roundedCircle width={100} height={100} className="me-3" />
              <h3 className="mb-0"><b>{user.name}</b></h3>
            </div>
            <ListGroup variant="flush" className="mt-3">
              <ListGroup.Item>Username: <span className="fw-bold">{user.nickname}</span></ListGroup.Item>
              <ListGroup.Item>Email: <span className="fw-bold">{user.email}</span></ListGroup.Item>
              <ListGroup.Item>Email Verficado: <span className="fw-bold">{user.email_verified ? 'Sim' : 'Não'}</span></ListGroup.Item>
              <ListGroup.Item>Último acesso: <span className="fw-bold">{formattedDate}</span></ListGroup.Item>
              <ListGroup.Item>Assinatura: <span className="fw-bold">{message}</span></ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};
