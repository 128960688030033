import React, { useEffect } from "react";
import { Container, Alert } from 'react-bootstrap';

const Metodologia = () => {
    useEffect(() => {
        document.title = "Metodologia de cálculo do índice de preço das criptomoedas · MercadoCripto"
    }, []);
    return (
        <Container className="p-3 mt-3 about">
            <div className="px-3 pt-2 pb-3">
                <p>O portal mercadocripto foi criado com o objetivo de disponibilizar os dados de volume e preço de todas as corretoras nacionais de uma forma simples, objetiva e transparente.</p>
                <p>O rápido crescimento do número de corretoras de Bitcoin (BTC) e criptomoedas nacionais, aliado à grande variação de preço entre elas, motivou a criação de um índice de preço único para cada criptomoeda negociada em Real Brasileiro (BRL).</p>
            </div>
            <hr />
            <h2 className="pb-2 h5 font-weight-bolder">Metodologia de Cáculo do Índice</h2>
            <div className="px-3 pt-1 pb-3">
                <p>O valor do índice de cada criptomoeda é calculado a partir do preço negociado em cada corretora, ponderado pelo volume negociado, conforme a fórmula abaixo:</p>
                <var className="p-4 align-middle"><span style={{ fontSize: "30px", fontFamily: "serif" }}>&sum; </span>(P * V<sub>i</sub> / V) </var> onde:
                <p><var>P</var> = Preço da criptomoeda na corretora.<br />
                    <var>V<sub>i</sub></var> = Volume negociado da criptomoeda nas últimas 24 negociado na corretora.<br />
                    <var>V</var> = Soma volume negociado da criptomoeda nas últimas 24h em todas as corretoras monitoradas.</p>

            </div>
            <hr />
            <Alert variant="info" className="border-info">
                <Alert.Heading>Você tem uma corretora?</Alert.Heading>
                <p>Entre em contato para anunciar ou para listar sua corretora: <Alert.Link href='mailto:mercadocripto@livecoins.com.br'>mercadocripto@livecoins.com.br</Alert.Link></p>
            </Alert>
            <h2 className="pb-2 font-weight-bolder h5">Critérios para Listagem</h2>
            <div className="px-3 pt-1 pb-3">
                <p>Para que uma corretora seja listada no portal e adicionada ao índice, adotamos as seguintes diretrizes para admissibilidade:</p>
                <ol className="about">
                    <li>A corretora disponibiliza uma API aberta e gratuita, de forma que os usuários sejam capazes de verificar o volume e o preço sem logar na plataforma.</li>
                    <li>A API pública deve conter um <span className="font-italic">endpoint</span> com um resumo de cada par negociado nas últimas 24h. O exemplo abaixo pode ser usado como guia:
                        <pre className="rounded p-4 mt-3 codeblock"><code>
                            <span className="text-white">&#123;</span><br />
                            "Symbol": <span className="string">"BTCBRL"</span><span className="text-white">,</span><br />
                            "Last": <span className="int">180590.52</span><span className="text-white">,</span><br />
                            "Volume": <span className="int">0.48826806</span><span className="text-white">,</span><br />
                            "Quote_Volume": <span className="int">90299.55</span><br />
                            <span className="text-white">&#125;</span>
                        </code></pre>
                    </li>
                    <li>Fácil verificação se os dados da API batem com os dados da plataforma.</li>
                    <li>Volume de negociação no par deve ser constantemente maior do que zero nas útlimas 24 horas.</li>
                </ol>
            </div>
        </Container>
    )
}

export default Metodologia