const defaultMessages = {
    notFound : "Nenhum resultado para a consulta realizada.", 
    default : "Erro 404. Página não encontrada."
}

export function doFetchJson(response, messageSpec = defaultMessages) {
    if(response.ok && response.headers.get("content-type") === 'application/json') {
        return response.json();
      } else {
        switch (response.status) {
            case 404:
                throw new Error((messageSpec.notFound) ? messageSpec.notFound : defaultMessages.notFound);
            case 500:
                throw new Error((messageSpec.notFound) ? messageSpec.notFound : defaultMessages.notFound);
            default:
                throw new Error((messageSpec.default) ? messageSpec.default : defaultMessages.default);
        }
      }
}