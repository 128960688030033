import React from "react";
import BootstrapTable from 'fad-react-bootstrap-table-next';
import { Link } from "react-router-dom";
import coinlogo from "./logos/coinlogo.svg";
import { ProgressBar } from 'react-bootstrap';

const CoinsTable = (props) => {
  var coins = props.data
  var fullvol = props.full

  const columns = [
    {
      dataField: 'SymbolName',
      text: 'Nome',
      sort: true,
      classes: "freeze text-center text-md-start",
      headerClasses: "freeze text-center text-md-start",
      formatter: (cell, row, rowIndex, extraData) => (<>
        <Link to={"/" + row.Symbol} className="stretched-link">
          <svg className="tablelogo" role="img" aria-labelledby="title">
            <title id="title">{row.SymbolName}</title>
            <use href={coinlogo + "#" + (row.Symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
          </svg>
          <br className="d-md-none" />
          {row.SymbolName} <span className="text-muted text-decoration-none"> {row.Symbol.slice(0, -3)}</span>
        </Link></>
      )
    }, {
      dataField: 'Symbol',
      text: 'Par',
      sort: true,
      hidden: true,
    }, {
      dataField: 'Last',
      text: 'Preço',
      sort: true,
      classes: 'toupdate',
      formatter: (cell, row, rowIndex, extraData) => {
        return <>{(row.Last > 0.1) ? row.Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + row.Last.toLocaleString('pt-BR', { style: 'decimal', maximumSignificantDigits: 8 }))}</>
      }
    }, {
      dataField: 'QuoteVol',
      text: 'Volume (24h)',
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => {
        return <>{row.QuoteVolume.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br />
          <span className="text-muted small">{parseFloat(row.Volume).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }) + " " + row.Symbol.slice(0, -3)}</span></>
      },
      sortValue: (cell, row, rowIndex, extraData) => (row.QuoteVolume)
    }, {
      dataField: 'VolumePercent',
      text: 'Volume(%)',
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => {
          return (<>
              <ProgressBar max={100} now={row.QuoteVolume/fullvol * 100} style={{ minWidth: "2em" }} variant="info" label={`${(row.QuoteVolume/fullvol).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 1 })}`} />
          </>)
      }
  }];

  const defaultSorted = [{
    dataField: 'QuoteVol',
    order: 'desc'
  }];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        hover
        bootstrap4
        striped
        keyField="Symbol"
        data={coins}
        columns={columns}
        defaultSorted={defaultSorted}
        headerClasses="border-top-0"
      />
    </>
  )
}
export default CoinsTable