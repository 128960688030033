import React, { useState, useEffect } from 'react';
import SearchBar from "./SearchBar";
import CoinList from "./CoinList";

const SearchPage = () => {
    const [input, setInput] = useState('');
    const [coinListDefault, setCoinListDefault] = useState();
    const [coinList, setCoinList] = useState();
    const [exchangeListDefault, setExchangeListDefault] = useState();
    const [exchangeList, setExchangeList] = useState();

    const fetchData = async () => {
        return await Promise.all([fetch(process.env.REACT_APP_API_URL + "/v1/symbols"), fetch(process.env.REACT_APP_API_URL + "/v1/exchanges/")])
            .then(([res1, res2]) => {
                return Promise.all([res1.json(), res2.json()])
            })
            .then(([res1, res2]) => {
                setCoinList(res1)
                setCoinListDefault(res1)
                setExchangeList(res2)
                setExchangeListDefault(res2)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const updateInput = async (input) => {
        if (coinListDefault !== undefined) {
            const notnull = coinListDefault.filter((val) => val.SymbolName !== null)
            const filtered = notnull.filter(coin => {
                return coin.SymbolName.toLowerCase().includes(input.toLowerCase()) || coin.Symbol.slice(0, -3).toLowerCase().includes(input.toLowerCase())
            })
            const notnullexchange = exchangeListDefault.filter((val) => val.SymbolName !== null)
            const filteredexchanges = notnullexchange.filter(exchange => {
                return exchange.Name.toLowerCase().includes(input.toLowerCase())
            })
            setInput(input);
            setCoinList(filtered);
            setExchangeList(filteredexchanges);
        } else return null
    }

    useEffect(() => { fetchData() }, []);

    if (input.length !== 0) {
        return (
            <>
                <SearchBar
                    input={input}
                    onChange={updateInput}
                />
                <CoinList onClick={updateInput} coinList={coinList} exchangeList={exchangeList} />
            </>
        );
    } else {
        return (
            <SearchBar
                input={input}
                onChange={updateInput}
            />)
    }
}

export default SearchPage