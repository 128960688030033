import React from 'react';
import { ReactComponent as ArrowUp } from './svg/arrow_up.svg';

const ScrollToTop = () => {

    function ScrolltoTopFunction() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <div className="float-end">
            <div className="back-to-top-wrapper position-fixed">
                <div id="topBtn" className="rounded-circle text-white btn btn-secondary shadow scrolltotop position-fixed bottom-0 end-0 me-3" onClick={() => ScrolltoTopFunction()}>
                    <ArrowUp style={{ right: "0" }} />
                </div>
            </div>
        </div>
    );
}

export default ScrollToTop;