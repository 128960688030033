const groupBy = function(lista, column, times) {
  var columns = {};
  var resultado = [];

  lista.forEach(function (item) {
    var reg = {};

    columns[item[column]] = columns[item[column]] || [];

    for (var i in item)
      if (i !== column)
        reg[i] = item[i];

    columns[item[column]].push(reg);
  });

  for (var i in columns) {
    var result = { name: i, data: [] };

    for (var t in times) {
      var monthVolume = 0.0;

      for (var dataExchange in columns[i]) {
        if (columns[i][dataExchange].Time === times[t]) {
          monthVolume = columns[i][dataExchange].Volume
        }
      }

      result.data.push(monthVolume)
    }

    resultado.push(result).toFixed(5);
  }
  return resultado;
}
export default groupBy