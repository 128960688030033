import React from "react";
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import logo from './Livecoins-Logo.jpg';
import { ReactComponent as Search } from './search-solid.svg'
import { ReactComponent as Twitter } from './twitter-brands.svg'
import { ReactComponent as Insta } from './instagram-brands.svg'
import { ReactComponent as Fb } from './facebook-square-brands.svg'
import { ReactComponent as Mail } from './envelope-regular.svg'

const LivecoinsHeader = () => (
    <Navbar sticky="top" id="livecoinsbar" bg="white" expand="lg"><Container className="px-3">
        <Navbar.Brand href="https://www.livecoins.com.br" rel="noopener noreferrer" target="_blank" ><img src={logo} width='99' height='22' alt="Livecoins" /></Navbar.Brand>
        <Nav className="mx-auto d-none d-lg-inline-flex">
            <NavDropdown title="Notícias" id="news-nav-dropdown">
                <NavDropdown.Item href="https://www.livecoins.com.br/bitcoin" rel="noopener noreferrer" target="_blank" >Bitcoin</NavDropdown.Item>
                <NavDropdown.Item href="https://www.livecoins.com.br/blockchain" rel="noopener noreferrer" target="_blank" >Blockchain</NavDropdown.Item>
                <NavDropdown.Item href="https://www.livecoins.com.br/altcoins" rel="noopener noreferrer" target="_blank" >Altcoins</NavDropdown.Item>
                <NavDropdown.Item href="https://www.livecoins.com.br/defi" rel="noopener noreferrer" target="_blank" >Defi</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/">Mercado Cripto</Nav.Link>
            <NavDropdown title="Comunidade" id="media-nav-dropdown">
                <NavDropdown.Item href="https://www.facebook.com/livecoinsbr" rel="noopener noreferrer" target="_blank" >Facebook</NavDropdown.Item>
                <NavDropdown.Item href="https://www.instagram.com/livecoins/" rel="noopener noreferrer" target="_blank" >Instagram</NavDropdown.Item>
                <NavDropdown.Item href="https://twitter.com/livecoinsBR" rel="noopener noreferrer" target="_blank" >Twitter</NavDropdown.Item>
                <NavDropdown.Item href="https://pt.quora.com/q/bitcoinbr" rel="noopener noreferrer" target="_blank" >Quora</NavDropdown.Item>
                <NavDropdown.Item href="https://t.me/livecoinsBr" rel="noopener noreferrer" target="_blank" >Telegram</NavDropdown.Item>
            </NavDropdown>
        </Nav>
        <Nav className="ml-auto d-none d-lg-inline-flex">
            <Nav.Link href="https://www.instagram.com/livecoins/" rel="noopener noreferrer" aria-label="Instagram" target="_blank" className="iconslive" ><Insta width="18" style={{ color: "#8224e3" }} /></Nav.Link>
            <Nav.Link href="https://www.facebook.com/livecoinsbr" rel="noopener noreferrer" aria-label="Facebook" target="_blank" className="iconslive" ><Fb width="18" style={{ color: "#1e73be" }} /></Nav.Link>
            <Nav.Link href="https://twitter.com/livecoinsBR" rel="noopener noreferrer" aria-label="Twitter" target="_blank" className="iconslive" ><Twitter width="18" style={{ color: "#23cbdd" }} /></Nav.Link>
            <Nav.Link href="https://livecoins.com.br/busca-livecoins" rel="noopener noreferrer" aria-label="Busca" target="_blank" className="iconslive" ><Search width="18" style={{ color: "#1e73be" }} /></Nav.Link>
            <Nav.Link href="https://livecoins.com.br/inscreva-se-receba-noticias-sobre-criptoeconomia-e-blockchain/" aria-label="Subscribe" rel="noopener noreferrer" target="_blank" className="iconslive" ><Mail width="18" style={{ color: "#1e73be" }} /></Nav.Link>
        </Nav>
    </Container>
    </Navbar>
)
export default LivecoinsHeader;


