import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';

const AdDiv = (props) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    return () => {
      // Clear the component here
      setShow(false);
    };
  }, []);


  if (show) {
    return (
      <Alert variant="primary" className="bd-callout p-4" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Compre relatórios personalizados</Alert.Heading>
        <p>Entre em contato para adquirar relatórios personalizados em formato excel (<code>.xls</code>), CSV (<code>.csv</code>) ou JSON (<code>.json</code>).<br/>Compre relatórios com dados históricos de qualquer moeda ou corretora.</p>
        <div>
          <a href='/assine' title="Saiba Mais" className="btn btn-lg btn-primary">Saiba mais</a>
        </div>
      </Alert>
    );
  }
  return <></>;
}

export default AdDiv;