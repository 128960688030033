import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import RelatoriosClosed from "../components/restricted/Relatorios-closed.js"

export const AdminPage = () => {

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    return () => {
    };
  }, [getAccessTokenSilently]);

  return (
    <>
      <RelatoriosClosed />
    </>
  );
};
