import React from "react";
import { ProgressBar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BootstrapTable from 'fad-react-bootstrap-table-next';
import exchangelogo from "./logos/exchangelogo.svg";

const ExchangesTable = (props) => {
    const exchanges = props.data
    const columns = [
        {
            dataField: 'Name',
            text: 'Corretora',
            sort: true,
            classes: "freeze",
            headerClasses: "freeze",
            formatter: (cell, row, rowIndex, extraData) => {
                return (
                    <>
                        <svg className="tablelogo mr-2" role="img" aria-labelledby="title">
                            <title id="title">{row.Name}</title>
                            <use href={exchangelogo + "#" + (row.Name.toLowerCase())}></use>
                        </svg>
                        <Link to={'/corretoras/' + row.Name} className="stretched-link">{row.Name}</Link>
                    </>
                )
            }
        }, {
            dataField: 'QuoteVolume',
            text: 'Volume(24h) R$',
            classes: "py-3",
            sort: true,
            formatter: (cell, row, rowIndex, extraData) => {
                return <>{parseFloat(row.QuoteVolume).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </>
            }
        }, {
            dataField: '24h',
            text: 'Volume (24h%)',
            sort: true,
            formatter: (cell, row, rowIndex, extraData) => {
                return <>
                    {(row.QuoteVolume - row.YesterdayVolume) / row.YesterdayVolume >= 0
                        ? <span className="text-success"> {((row.QuoteVolume - row.YesterdayVolume) / row.YesterdayVolume).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                        : <span className="text-danger"> {((row.QuoteVolume - row.YesterdayVolume) / row.YesterdayVolume).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                    }               </>
            }
        }, {
            dataField: 'nSymbols',
            text: '#Moedas (BRL)',
            sort: true,
            formatter: (cell, row, rowIndex, extraData) => {
                return <>{parseInt(row.Nsymbols)}</>
            },
            sortValue: (cell, row, rowIndex, extraData) => row.Nsymbols

        }, {
            dataField: 'VolumePercent',
            text: 'Volume(%)',
            sort: true,
            formatter: (cell, row, rowIndex, extraData) => {
                return (<>
                    <ProgressBar max={Math.max.apply(Math, exchanges.map(function (e) { return e.VolumePercent * 100; }))} now={row.VolumePercent * 100} style={{ minWidth: "2em" }} variant="info" label={`${row.VolumePercent.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 1 })}`} />
                </>)
            }
        }
    ]

    const defaultSorted = [{
        dataField: 'VolumePercent',
        order: 'desc'
    }];

    return (
        <>
            <BootstrapTable
                bordered={false}
                hover
                bootstrap4
                striped
                condensed
                keyField="Name"
                data={exchanges}
                columns={columns}
                defaultSorted={defaultSorted}
                headerClasses="header-class"
            />
        </>
    )
}
export default ExchangesTable