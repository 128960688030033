import React, { useCallback, useMemo } from "react";
import { ReactComponent as Downloadsvg } from './../svg/download.svg';
import { utils, writeFileXLSX } from 'xlsx';

const ExportExcel = (props) => {
  var xaxistime = props.xaxistime
  var rawreport = props.rawreport
  var xls_brl = useMemo(() => [], []);
  var xls_cripto = useMemo(() => [], []);
  xaxistime.forEach(t => {
    var item = {
      dia: t
    }
    var criptoitem={
      dia:t
    }

    var xaxisTimeDate = new Date(Date.parse(t));

    rawreport.forEach(resource => {
      var resourceTimeDate = new Date(Date.parse(resource.Time));
      var sameDay = xaxisTimeDate.getFullYear() === resourceTimeDate.getFullYear() && xaxisTimeDate.getMonth() === resourceTimeDate.getMonth() && xaxisTimeDate.getDate() === resourceTimeDate.getDate();

      if (sameDay) {
        item[resource.Exchange] = resource.QuoteVolume
        criptoitem[resource.Exchange] = resource.Volume
      }
    });
    xls_cripto.push(criptoitem);
    xls_brl.push(item);
  });

  const exportFile = useCallback((volumetype,data) => () => {
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "mercadocripto-" + props.symbol + "-"+volumetype+ "-" + (!props.month ? 'anual' : ('mensal-'+xaxistime[0].substring(0,7))) + ".xlsx");
  }, [xaxistime,props.symbol, props.month]);

  return (
    <>
      <div>
        <button type="button" className="btn btn-excel position-relative" onClick={exportFile("QuoteVolumeBRL",xls_brl)} title="Download Excel">
          <span><Downloadsvg width="20" height="20" /> .xlsx</span>
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info" title="Volume em BRL">
            BRL
            <span className="visually-hidden">Conteúdo de assinantes</span>
          </span>
        </button>
      </div>
      <div className="mt-3">
        <button type="button" className="btn btn-excel position-relative" onClick={exportFile("Volume",xls_cripto)} title="Download Excel">
          <span><Downloadsvg width="20" height="20" /> .xlsx</span>
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning" title="Volume em Cripto">
            Cripto
            <span className="visually-hidden">Conteúdo de assinantes</span>
          </span>
        </button>
      </div>
    </>
  )
}

export default ExportExcel